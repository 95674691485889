import React from "react";
import '../../styles/main.css';
import { Appraisal_Tag, Component } from "../../backend-generated-types";
import { generate_part_page_link } from "../../helpers/link_helper";
import { Chip, Link, Stack, Tooltip, Typography } from "@mui/material";

type Props = {
    appraisal_tags: Appraisal_Tag[];
}

const comp_selection_style = {
}

const Appraisal_Tags = (props: Props) => {
    
    return (<>
        <div style={comp_selection_style}>
            <h3>{`Appraisal Tags`}</h3>

            
            {props.appraisal_tags ?
                <Stack direction="row" spacing={1}>
                    {props.appraisal_tags.map(t => 
                        <Tooltip title={t.description}><Chip color={'primary'} label={t.label} /></Tooltip>
                    )}
                </Stack>
            : <Typography sx={{fontStyle: 'italic'}}>No tags included on this build.</Typography>}
        </div>
    </>)
}

export default Appraisal_Tags;