import React from "react";
import "../styles/main.css";
import { Component } from "../backend-generated-types";
import { generate_part_page_link } from "../helpers/link_helper";
import { Link } from "@mui/material";

type Props = {
  header: string;
  component_type: string;
  components?: Component[];
};

const comp_selection_style = {
  margin: "20px",
  width: "100%",
  maxWidth: "250px",
  fontFamily: "Ubuntu",
};

const Component_Read_Only = (props: Props) => {
  return (
    <>
      <div style={comp_selection_style}>
        <h3>{props.header}</h3>
        {!!props.components?.length &&
          props.components.map((c) => (
            <>
              <p>
                <Link
                  underline="none"
                  href={generate_part_page_link(props.component_type, c.id)}
                >
                  {c.base_name}
                </Link>
              </p>
            </>
          ))}
      </div>
    </>
  );
};

export default Component_Read_Only;
